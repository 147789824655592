import "./index.scss";
import React from "react";
import {httpService, IcButton} from 'ichigo-common-lib'


const F000HomePage = () => {
    const handleClick = () => {
        console.log('onclick')
        httpService.get('/stub/get/json', {}).then((response) => {
            console.log(response)
        })
    }
    return <div>
        <button children={
            <IcButton onClick={handleClick}/>
        }></button>
    </div>;
};

export default F000HomePage;
